<template>
    <v-row
      no-gutters
      class="h-100"
    >
      <v-col
        cols="12"
        offset="0"
        md="4"
        offset-md="4"
        class="d-flex flex-column justify-center align-center" >
        <v-card
        v-if="!passwordUpdated"
          class="w-100 rounded-lg py-4"
        >
          <v-card-title class="px-8 mb-2">{{ $t('resetPassword.title') }}</v-card-title>
          <v-card-text class="px-8">
            <v-form
              ref="loginForm"
              v-model="valid"
            >
              <v-row>
                <v-col
                  cols="12"
                >
                <v-text-field
                    v-model="form.password"
                    type="password"
                    autocomplete
                    placeholder=" "
                    persistent-placeholder
                    name="input-10-1"
                    :label="$t('login.password')"
                    outlined
                    dense
                    validate-on-blur
                    :rules="passwordRules"
                    @keyup.enter="submitHandle"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                <v-text-field
                    v-model="form.passwordRepeat"
                    type="password"
                    autocomplete
                    placeholder=" "
                    persistent-placeholder
                    name="input-10-1"
                    :label="$t('resetPassword.confirmPassword')"
                    outlined
                    dense
                    validate-on-blur
                    :rules="passwordRepeat"
                    @keyup.enter="submitHandle"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-8 mb-4">
            <v-btn
              color="primary"
              block
              depressed
              :disabled="submitLoading"
              :loading="submitLoading"
              @click="submitHandle"
            >
              {{ $t('login.submitPassword') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-title class="px-8 mb-2">{{ $t('changePassword.successfullyUpdated') }}</v-card-title>
          <v-card-text class="px-8">{{ $t('changePassword.successfullyUpdatedCanProceedToLogin') }}</v-card-text>
          <v-card-actions class="px-8 mb-4">
            <v-btn
              color="primary"
              block
              depressed
              :disabled="submitLoading"
              :loading="submitLoading"
              @click="$router.push({ name: 'Login' })"
            >
              {{ $t('login.title') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
</template>
<script>
import Service from '@/services'

export default {
  name: 'Login',
  data () {
    return {
      form: {
        token: '',
        password: '',
        passwordRepeat: ''
      },
      submitLoading: false,
      valid: true,
      passwordRules: [
        v => !!v.trim() || this.$t('validation.passwordRequired')
      ],
      passwordRepeat: [
        v => v === this.form.password || this.$t('validation.passwordRepeat')
      ],
      passwordUpdated: false
    }
  },
  async created () {
    this.form.token = new URLSearchParams(window.location.search).get('token')
    if (!this.form.token) { await this.$router.push({ name: 'Login' }) }
  },
  methods: {
    async submitHandle () {
      if (!this.$refs.loginForm.validate()) {
        return
      }
      this.submitLoading = true
      try {
        await Service.post('Authentication/FirstLogin', {
          token: this.form.token,
          password: this.form.password,
          passwordRepeat: this.form.passwordRepeat
        })
        this.passwordUpdated = true
      } catch (e) {
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>
